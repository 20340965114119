<template>
  <app-modal-invoice-payment-detail :show="showModalDetail" @close="showModalDetail = false" />
  <div class="flex flex-col items-center w-full px-3 pt-10 bg-gray-800 md:px-0">
    <div class="flex flex-col w-4/5 max-w-sm py-4 mt-4 mb-4 text-center bg-white rounded-md md:max-w-lg">
      <div class="flex justify-between px-8">
        <div class="flex flex-col items-start justify-start py-5">
          <app-image v-if="getBusinessLogo()" :src="getBusinessLogo()" class="flex-none w-16 bg-white" />
          <app-image v-else-if="getBusinessIcon()" :src="getBusinessIcon()" class="flex-none w-16 bg-white" />
          <div class="pt-4">
            <p class="mb-2 text-sm font-medium text-gray-900 uppercase">
              {{ business?.name }}
            </p>
          </div>
          <p class="mb-2 text-sm text-gray-400 uppercase">
            {{ business?.detail?.company_reg_no }}
          </p>
        </div>
        <div class="flex flex-col justify-end py-8 text-right">
          <p class="mb-2 font-bold text-right uppercase">{{ $t("layout.header.invoice") }}</p>
          <p class="text-sm text-gray-400">{{ $t("invoice.invoice_pdf.amount_to_pay") }}</p>
          <p class="text-2xl font-bold">{{ $formats.currency(invoice.currency, invoice.total_amount) }}</p>
          <p class="text-sm text-gray-400">{{ $t(`invoice.create.types.${invoice.type}`) }}</p>
        </div>
      </div>
      <div class="w-full mb-2 border-t"></div>
      <div class="flex flex-row justify-between px-8 py-4">
        <div class="flex flex-col justify-start space-y-2">
          <div class="flex flex-row items-center space-x-4 text-left">
            <p class="items-center w-8 text-sm text-left text-gray-400">
              {{ $t("general.to") }}
            </p>
            <p class="text-sm">{{ customer.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4 text-left">
            <p class="w-8 text-sm text-gray-400">
              {{ $t("general.from") }}
            </p>
            <p class="text-sm">{{ business?.name }}</p>
          </div>
          <div class="flex flex-row items-center space-x-4 text-left">
            <p class="w-8 text-sm text-gray-400">
              {{ $t("invoice.invoice_pdf.memo") }}
            </p>
            <p class="text-sm">{{ invoice.memo }}</p>
          </div>
        </div>
      </div>
      <div class="w-full mb-2 border-t"></div>

      <button class="flex flex-row items-center justify-between mx-8 my-4 text-left" @click="toggleDetails">
        <p>View Details</p>
        <!-- <p>></p> -->
        <app-icon name="ChevronRightIcon" class="w-5 h-5 text-gray-400 transition transform"
          :class="[showDetails ? '-rotate-90' : 'rotate-0']" />
      </button>

    </div>

    <div class="flex flex-col w-4/5 max-w-sm px-6 py-6 mt-4 space-y-4 bg-white rounded-md md:max-w-lg">

      <!-- <div class="py-5">
          <p class="text-gray-400">Payment Method</p>
        </div> -->
      <app-proceed-payment-method :payment_methods="payment_methods" @proceed_payment="proceedPayment"
        :preview_mode="previewMode" :test_mode="testMode" :errors="errors" :loading="loading" />

    </div>
    <div class="justify-center m-10">
      <app-logo-powered-by-swipe-light />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    masterBusinessMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showModalDetail: false,
      testMode: false,
      showDetails: false,
    };
  },

  async mounted() {
    if (this.$route.query.id == null) {
      this.testMode = this.$store.getters["businessStore/test_mode"] ?? false;
      await this.getDataForPreview();
      return;
    }

    if (this.$route.query.test != null && this.$route.query.test == "true") {
      this.testMode = true;
    }

    this.$store.commit("businessStore/setTestModePublicPage", this.testMode);

    await this.getDataForInvoice(this.$route.query.id);
  },

  computed: {
    loading() {
      return this.$store.getters["invoiceStore/loading"];
    },
    invoice() {
      return this.$store.getters["invoiceStore/invoice"];
    },
    customer() {
      return this.$store.getters["customerStore/customer"];
    },
    business() {
      return this.masterBusinessMode
        ? this.payment_link?.business
        : this.$store.getters["businessStore/business"];
    },
    payment_methods() {
      return this.$store.getters["paymentMethodStore/payment_methods"];
    },
    errors() {
      return this.$store.getters["invoiceStore/errors"];
    },
    payment_link_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/payment/link/pay?id=<" +
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    toggleDetails() {
      this.showModalDetail = !this.showModalDetail;
    },
    getBusinessLogo() {
      return this.business?.media?.find(
        (media) => media.collection_name == "logo"
      )?.original_url;
    },
    getBusinessIcon() {
      return this.business.media?.find((media) => media.collection_name == "icon")
        ?.original_url;
    },
    async proceedPayment(data) {
      if (
        this.previewMode ||
        this.payment_link._id == undefined ||
        data.business_payment_gateway_method_id == 0
      ) {
        return;
      }

      this.$store.commit("paymentStore/resetState", {});

      const paymentAttempt = await this.$store.dispatch(
        "paymentStore/proceedPaymentAttempt",
        {
          businessPaymentGatewayMethodId: data.business_payment_gateway_method_id,
          bank_info: data.bank_info,
        }
      );

      if (paymentAttempt != null) {
        window.location.href = paymentAttempt.process_payment_url;
      }
    },
    async getDataForInvoice(invoiceId) {
      let invoice = await this.$store.dispatch("invoiceStore/getSingleInvoice", invoiceId);

      if (this.$lodash.isEmpty(invoice.data)) {
        this.$store.commit("invoiceStore/setInvoice", null);
        return;
      }
      this.$store.commit(
        "businessStore/setBusiness",
        this.invoice.business
      );
      this.$store.commit(
        "customerStore/setCustomer",
        this.invoice.customer
      );
      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },
    async getDataForPreview() {
      await this.$store.dispatch(
        "businessStore/getBusinessFromSelectedRoleBusinessId"
      );
      await this.$store.dispatch(
        "paymentMethodStore/getPaymentMethods",
        this.business.id
      );
    },
  },
};
</script>